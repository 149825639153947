import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors } from "../../../styles/helpers"

import BackgroundOne from "../../Icons/BackgroundOne"

const TwoHeroImages = ({ data }) => {
  const imageOneDisplay = getImage(
    data.twoHeroImagesOne.localFile.childImageSharp.gatsbyImageData
  )
  const imageOneAlt = data.twoHeroImagesOne.altText

  const imageTwoDisplay = getImage(
    data.twoHeroImagesTwo.localFile.childImageSharp.gatsbyImageData
  )
  const imageTwoAlt = data.twoHeroImagesTwo.altText
  return (
    <DivStyled>
      <div className="wrapper">
        <div className="img-wrap img-one">
          <GatsbyImage
            image={imageOneDisplay}
            alt={imageOneAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="img-wrap img-two">
          <GatsbyImage
            image={imageTwoDisplay}
            alt={imageTwoAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </DivStyled>
  )
}

const DivStyled = styled.div`
  position: relative;
  padding-bottom: 20rem;

  .wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    min-height: 50rem;
    border-bottom: 2.5rem solid ${colors.colorPrimary};

    @media (min-width: 768px) {
      min-height: 50rem;
    }

    @media (min-width: 1025px) {
      min-height: 50rem;
    }
  }

  .img-wrap {
    position: absolute;
    top: 0;
    bottom: 0;

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .img-one {
    display: none;
    @media (min-width: 768px) {
      display: block;
      right: 75%;
      left: 0;
    }
  }

  .img-two {
    right: 0;
    left: 0;
    @media (min-width: 768px) {
      right: 0;
      left: 26%;
    }
  }

  .bg-one {
    position: absolute;
    bottom: -15rem;
    right: -20rem;
    max-width: 40rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -15rem;
      right: -10rem;
    }

    @media (min-width: 1025px) {
      bottom: -15rem;
      right: -10rem;
    }

    @media (min-width: 1200px) {
      bottom: -15rem;
      right: -10rem;
    }
  }
`

export default TwoHeroImages
