import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import IntroImageContent from "../components/templates/treatmentSingle/IntroImageContent"
import BodyContent from "../components/templates/treatmentSingle/BodyContent"
import TwoHeroImages from "../components/templates/treatmentSingle/TwoHeroImages"
import ReadyHero from "../components/templates/common/ReadyHero"

const TreatmentSingle = props => {
  const { introImageContent, bodyContent, twoHeros, readyHero, seoInfo } =
    props.data
  const introImageContentData =
    introImageContent.template.templatePageTreatmentSingle
  const bodyContentData = bodyContent.template.templatePageTreatmentSingle
  const twoHerosData = twoHeros.template.templatePageTreatmentSingle
  const readyHeroData = readyHero.template.templatePageTreatmentSingle
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <IntroImageContent data={introImageContentData} />
      <BodyContent data={bodyContentData} />
      <TwoHeroImages data={twoHerosData} />
      <ReadyHero data={readyHeroData} />
    </Layout>
  )
}

export const treatmentSingleTempQuery = graphql`
  query treatmentSingleTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    introImageContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TreatmentSingle {
          templatePageTreatmentSingle {
            iicContent
            iicTitle
            iicImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    bodyContent: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TreatmentSingle {
          templatePageTreatmentSingle {
            bodyContent
          }
        }
      }
    }

    twoHeros: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TreatmentSingle {
          templatePageTreatmentSingle {
            twoHeroImagesOne {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
            twoHeroImagesTwo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }

    readyHero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TreatmentSingle {
          templatePageTreatmentSingle {
            readyHeroTitle
            readyHeroContent
            readyHeroButtonText
            readyHeroButtonSlug
            readyHeroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TreatmentSingle
