import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B2LightBlue, H1Blue, standardWrapper } from "../../../styles/helpers"

import BackgroundOne from "../../Icons/BackgroundOne"

const IntroImageContent = ({ data }) => {
  const imageDisplay = getImage(
    data.iicImage.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.iicImage.altText
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="image">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
          <div className="bg-one">
            <BackgroundOne />
          </div>
        </div>
        <div className="content">
          <div className="content__title">
            <h2>{data.iicTitle}</h2>
          </div>
          <div
            className="content__paragraph"
            dangerouslySetInnerHTML={{ __html: data.iicContent }}
          />
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  margin-top: 5rem;

  .wrapper {
    ${standardWrapper};
    align-items: flex-start;
  }

  .image {
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(33.333333%);
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(66.666666%);
      padding: 0 5rem;
    }

    &__title {
      h2 {
        ${H1Blue};
      }
    }

    &__paragraph {
      p {
        ${B2LightBlue};
      }
    }
  }

  .bg-one {
    position: absolute;
    bottom: 0rem;
    left: -15rem;
    max-width: 40rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -5rem;
      left: -15rem;
    }

    @media (min-width: 1025px) {
      bottom: -5rem;
      left: -15rem;
    }
  }
`

export default IntroImageContent
