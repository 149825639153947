import React, { useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { B1White, Btn1Clear, H1White } from "../../../styles/helpers"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ReadyHero = ({ data }) => {
  const imageDisplay = getImage(
    data.readyHeroImage.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.readyHeroImage.altText

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#ready-hero-trigger`,
          markers: false,
          start: "top 50%",
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        `#ready-hero-trigger .block-content`,
        {
          autoAlpha: 0,
          x: -150,
        },
        {
          autoAlpha: 1,
          x: 0,
          duration: 0.75,
        }
      )
      .fromTo(
        `#ready-hero-trigger .block-image`,
        {
          autoAlpha: 0,
          y: 150,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.75,
        }
      )
  }, [])

  return (
    <SectionStyled>
      <div id="ready-hero-trigger" className="wrapper">
        <div className="ready-hero">
          <div className="block-content">
            <div className="block-content__title">
              <h2>{data.readyHeroTitle}</h2>
              <p>{data.readyHeroContent}</p>
              <div className="block-content__links">
                <Link to={`/${data.readyHeroButtonSlug}`}>
                  {data.readyHeroButtonText}
                </Link>
              </div>
            </div>
          </div>
          <div className="block-image">
            <GatsbyImage
              image={imageDisplay}
              alt={imageAlt}
              layout="fullWidth"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  overflow: hidden;
  .wrapper {
  }

  .ready-hero {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .block-content {
      width: 100%;
      padding: 5rem 2rem;
      background-color: rgba(0, 41, 106, 1);

      @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 0;
        width: calc(60%);
        padding: 2rem;
        background-color: rgba(0, 41, 106, 0.9);
        text-align: left;
        z-index: 500;
      }

      @media (min-width: 1025px) {
        padding: 5rem 5rem 5rem 15rem;
      }

      &__title {
        width: 100%;
        text-align: left;

        h2 {
          ${H1White};
          margin: 0;
          margin-bottom: 2.5rem;
        }

        p {
          ${B1White};
        }
      }

      &__links {
        width: 100%;
        text-align: left;

        @media (min-width: 768px) {
          margin-top: 0;
        }

        a {
          ${Btn1Clear};
        }
      }
    }

    .block-image {
      width: 100%;
    }
  }
`

export default ReadyHero
